import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "../styles/Search.module.css";
import Button from "../components/button";
import { searchByQueryString } from "../sdk/entry";
import { useTranslation } from "react-i18next";

interface SearchSEO {
  meta_title?: string;
  meta_description?: string;
}
interface SearchResponse {
  title?: string;
  description?: string;
  summary?: string;
  sub_title: string;
  seo?: SearchSEO;
  overview?: SearchSEO;
  url: string;
}

export default function Search() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState(searchParams.get("s") || "");
  const [data, setData] = useState<any>([]);
  const { i18n } = useTranslation();
  const fetchData = async (query: string) => {
    if (!query) return;
      const data = await searchByQueryString(query, i18n.language);
      setData(data.filter((d: SearchResponse) => d.title || d.seo?.meta_title || d.overview?.meta_title));
  };

  useEffect(() => {
    const searchString = searchParams.get("s");
    if (searchString) {
      fetchData(searchString);
    }
  }, [searchParams, i18n.language]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleSearch = () => {
    navigate("/search?s=" + searchValue);
  };

  const isHTML = (str: string): boolean => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = str;
    return Array.from(tempDiv.childNodes).some((node) => node.nodeType === 1);
  };

  const getSearchIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="currentColor"
        style={{ marginLeft: "var(--xs)" }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 4.25042C6.50544 4.25042 5.55161 4.64551 4.84835 5.34877C4.14509 6.05203 3.75 7.00586 3.75 8.00042C3.75 8.99498 4.14509 9.94881 4.84835 10.6521C5.55161 11.3553 6.50544 11.7504 7.5 11.7504C8.49456 11.7504 9.44839 11.3553 10.1517 10.6521C10.8549 9.94881 11.25 8.99498 11.25 8.00042C11.25 7.00586 10.8549 6.05203 10.1517 5.34877C9.44839 4.64551 8.49456 4.25042 7.5 4.25042ZM2.25 8.00042C2.25 7.16886 2.44753 6.3492 2.82632 5.60893C3.20512 4.86866 3.75434 4.22896 4.42875 3.7425C5.10317 3.25604 5.8835 2.93674 6.70548 2.8109C7.52745 2.68505 8.36757 2.75626 9.15664 3.01866C9.94571 3.28105 10.6612 3.72713 11.2441 4.32017C11.827 4.9132 12.2607 5.63621 12.5095 6.42968C12.7583 7.22314 12.8151 8.06435 12.6751 8.88405C12.5351 9.70374 12.2025 10.4785 11.7045 11.1444L15.5303 14.9694C15.671 15.11 15.7501 15.3008 15.7502 15.4998C15.7502 15.6987 15.6713 15.8896 15.5306 16.0303C15.39 16.171 15.1992 16.2501 15.0003 16.2502C14.8013 16.2503 14.6105 16.1713 14.4697 16.0307L10.6447 12.2057C9.86439 12.7894 8.93694 13.1444 7.96625 13.231C6.99556 13.3175 6.01994 13.1322 5.14861 12.6957C4.27728 12.2592 3.54463 11.5888 3.0327 10.7596C2.52077 9.93034 2.24976 8.97496 2.25 8.00042Z"
        />
      </svg>
    );
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      // Update the URL with the search query
      navigate(`/search?s=${encodeURIComponent(searchValue)}`);
    }
  };

  const getSearchButton = (width: string, height: string, fontSize: string) => {
    return (
      <Button
        text={`Search`}
        showIcon={true}
        icon={getSearchIcon()}
        onClick={handleSearch}
        propHeight={height}
        propWidth={width}
        propFontSize={fontSize}
        propBackgroundColor="var(--brand-primary-500)"
        propBorderColor="var(--brand-primary-500)"
        propTextColor="var(--white)"
        propHoverText="var(--brand-primary-500)"
        propHoverBackgroundColor="var(--white)"
      />
    );
  };

  const getTitle = (s: SearchResponse) => s?.seo?.meta_title || s?.overview?.meta_title || s?.title || '';
  const getDescription = (s: SearchResponse) => s?.seo?.meta_description || s?.overview?.meta_description || s?.description || s?.summary || '';

  return (
    <div className={styles.container}>
      <div className={styles.headerWrapper}>
        <div className={styles.header}>
          <h3 className={styles.search}>Search</h3>
          <div className={styles.searchInputBar}>
            <input
              className={styles.searchInput}
              value={searchValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
            <div id="searchButton" className={styles.searchButton}>
              {getSearchButton("6.125rem", "1.75rem", "0.8125rem")}
            </div>
            <div className={styles.searchButtonMobile}>
              {getSearchButton("7.6525rem", "2.625rem", "1rem")}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.content}>

        {data?.map((d: SearchResponse) => (
          <div key={d.title} className={styles.searchItem}>
            {(getTitle(d)) && (
              <p className={styles.title} onClick={() => navigate(d.url)}>
                {getTitle(d)}
              </p>
            )}
            {d?.sub_title && <p className={styles.subTitle}>{d.sub_title}</p>}
            {(getDescription(d)) &&
              (isHTML(getDescription(d)) ? (
                <p
                  className={styles.description}
                  dangerouslySetInnerHTML={{
                    __html: getDescription(d),
                  }}
                ></p>
              ) : (
                <p className={styles.description}>
                  {getDescription(d)}
                </p>
              ))}
          </div>
        ))}
      </div>
    </div>
  );
}
